/* components/HomePage.js */
/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
// import Weather from './Weather';
import useTranslation from 'next-translate/useTranslation';

import dynamic from 'next/dynamic';
import Spinner from './Spinner';
import Pulse from './Pulse';

// Dynamically import components
const FlightTable = dynamic(() => import('./FlightTable'));
const AffiliateLink = dynamic(() => import('./AffiliateLink'));
const MyDatePicker = dynamic(() => import('./DatePicker'));

function HomePage({
    waitTime,
    lastUpdated,
    metaTags,
    error,
    delayedFlights,
    summary,
    lastUpdatedUpdated,
    timezone
    // weatherData
}) {
    const { t, lang } = useTranslation('home');
    const [userCountry, setUserCountry] = useState(null);
    const [userLocale, setUserLocale] = useState(lang);
    const [flashClass, setFlashClass] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [summaryPreview, setSummaryPreview] = useState(
        <div className="flex h-[185px] items-center justify-center">
            <Spinner />
        </div>
    );
    // Set the flash class based on lastUpdatedUpdated

    const handleDateChange = (date) => {
        setSelectedDate(date);

        if (date && date.toTimeString().slice(0, 5) !== '00:00') {
            const formattedDate = encodeURIComponent(date.toISOString());
            window.location.href = `/statistics?to=${formattedDate}`;
        }
    };

    useEffect(() => {
        async function getUserInfo() {
            try {
                if (userLocale === 'da') return;
                const response = await fetch(
                    'https://get.geojs.io/v1/ip/country.json'
                );
                const data = await response.json();
                const country = data.country;
                const locale = lang || data.country.toLowerCase();
                setUserCountry(country);
                setUserLocale(locale);
            } catch (error) {
                // console.error('Error fetching user info:', error);
            }
        }

        if (!userLocale || !userCountry) {
            getUserInfo();
        }
    }, [userLocale, lang, userCountry]);

    useEffect(() => {
        if (lastUpdatedUpdated) {
            // Apply the flash class
            setFlashClass('flash');

            // Remove the flash class after a delay (e.g., 1 second)
            setTimeout(() => {
                setFlashClass('');
            }, 3000); // Adjust the delay as needed 1697953548
        }
    }, [lastUpdatedUpdated]);

    useEffect(() => {
        const formatSummaryPreview = (summary) => {
            const timePeriods = [
                {
                    key: '1YearAgo',
                    messageKey: `${t('one_year_ago')}`
                },
                {
                    key: '6MonthsAgo',
                    messageKey: `${t('six_months_ago')}`
                },
                {
                    key: '3MonthsAgo',
                    messageKey: `${t('three_months_ago')}`
                },
                {
                    key: '1MonthAgo',
                    messageKey: `${t('one_month_ago')}`
                },
                {
                    key: '1WeekAgo',
                    messageKey: `${t('one_week_ago')}`
                },
                {
                    key: '1DayAgo',
                    messageKey: `${t('one_day_ago')}`
                }
            ];

            let messageOfAverageRange = '';
            let highestLevelOfAverageRange = '';

            for (const period of timePeriods) {
                if (summary.summary[period.key].averageRange !== 'N/A') {
                    messageOfAverageRange = period.messageKey;
                    highestLevelOfAverageRange =
                        summary.summary[period.key].averageRange;
                    break; // Break out of the loop once a valid period is found
                }
            }

            return (
                <>
                    <p>{messageOfAverageRange}</p>
                    <p className="p-3 font-bold text-xl">
                        {highestLevelOfAverageRange} {t('common:minutes')}
                    </p>
                    <p>{t('when_flight')}</p>
                    <div className="text-black p-3">
                        <MyDatePicker
                            selectedDate={selectedDate}
                            onChange={handleDateChange}
                            setSelectedDate={setSelectedDate}
                        />
                    </div>
                </>
            );
        };

        if (summary) {
            setSummaryPreview(formatSummaryPreview(summary));
        }
    }, [summary, selectedDate, t]);

    return (
        <div className="px-8 h-full">
            <Head>
                <title>{t('common:title')}</title>
                {metaTags &&
                    metaTags.map((tag, index) => {
                        if (tag.property) {
                            return (
                                <meta
                                    key={`meta-tag-${index}`}
                                    property={tag.property}
                                    content={
                                        tag.localeIdentifier
                                            ? t(
                                                  `common:${tag.localeIdentifier}`,
                                                  tag.localeVariables
                                              )
                                            : tag.content
                                    }
                                />
                            );
                        } else if (tag.name) {
                            return (
                                <meta
                                    key={`meta-tag-${index}`}
                                    name={tag.name}
                                    content={
                                        tag.localeIdentifier
                                            ? t(
                                                  `common:${tag.localeIdentifier}`,
                                                  tag.localeVariables
                                              )
                                            : tag.content
                                    }
                                />
                            );
                        } else {
                            return null; // Handle other tag types if needed
                        }
                    })}
                {/* <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2658857150579605"
                    crossOrigin="anonymous"
                ></script> */}
            </Head>

            <main className="main flex flex-col justify-center items-center text-center mb-10 h-full">
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 rounded p-5 font-bold text-xl">
                        {error}
                    </div>
                )}
                {!error && (
                    <>
                        <div className="mt-4 md:mt-[4rem] flex-grow">
                            <div className="flex flex-col flex-1">
                                <div className="m-auto flex flex-col">
                                    <h1 className="m-4 text-2xl center leading-9">
                                        {t('current_wait') + ':'}
                                    </h1>
                                    {waitTime && lastUpdated ? (
                                        <div>
                                            <div className="text-6xl my-3">
                                                {waitTime} {t('common:minutes')}
                                            </div>
                                            <small
                                                className={`${flashClass} md:px-5 rounded`}
                                            >
                                                {t('common:last_updated')}:{' '}
                                                {new Date(
                                                    lastUpdated
                                                ).toLocaleString()}{' '}
                                                ({timezone})
                                            </small>
                                        </div>
                                    ) : (
                                        <div className="flex h-[108px] items-center justify-center">
                                            <Spinner />
                                        </div>
                                    )}
                                    <Pulse />
                                    <div>{summaryPreview}</div>
                                </div>
                            </div>

                            {/* {(userCountry === 'DK' ||
                                userLocale === 'da' ||
                                userLocale === 'dk') && (
                                <div className="mt-10">
                                    <AffiliateLink
                                        {...{
                                            type: 'text',
                                            text: t('common:affiliate_text_1'),
                                            umamiId: 'affiliate-1',
                                            className: 'underline'
                                        }}
                                    />
                                </div>
                            )} */}

                            <FlightTable flights={delayedFlights} />

                            {/* <button
                                className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 transition text-sm"
                                onClick={() => {
                                    if (typeof umami !== 'undefined') {
                                        umami.track('cph-airport-map');
                                    }
                                    window.location.href =
                                        '/cph-airport_map-after-security-v02-june2023.pdf';
                                }}
                            >
                                {t('common:map_over_cph_airport')}
                            </button> */}

                            {/* <Weather weatherData={weatherData} /> */}
                        </div>
                    </>
                )}
            </main>
        </div>
    );
}

export default HomePage;
